<template>
  <div
    class="smartslider-wrapper"
    v-touch:swipe.right="prevElement"
    v-touch:swipe.left="nextElement">
    <div class="smartslider">
      <div class="smartslider-content">
        <div
          v-if="hasPreviousElement"
          @click.prevent="prevElement"
          class="smartslider-arrow smartslider-arrow--left">
          <icon
            size="xlarge"
            src="/icons/chevron-left.svg">
          </icon>
        </div>
        <div class="smartslider-preview">
          <img
            :src="previewMedia"
            v-if="previewMediaType === 'image'">
          <video
            loop
            muted
            autoplay
            controls
            class="smartslider-video"
            v-if="previewMediaType === 'video'">
            <source :src="previewMedia" :type="previewMediaOptions?.mimeType">
          </video>
        </div>
        <div
          v-if="hasNextElement"
          @click.prevent="nextElement"
          class="smartslider-arrow smartslider-arrow--right">
          <icon
            size="xlarge"
            src="/icons/chevron-right.svg">
          </icon>
        </div>
      </div>
      <div
        v-if="elements.length > 0"
        class="smartslider-thumbnails">
        <smart-slider-thumbnail
          :key="idx"
          :category="category"
          :active="elementIndex === idx"
          @select="elementIndex = idx"
          v-for="(category, idx) in elements"
        />
      </div>
    </div>

    <!-- Controls -->
    <div class="smartslider-controls">
      <div
        @click.prevent="toggleMenu"
        class="smartslider-control">
        <icon
          size="large"
          :key="menu"
          :src="menu ? '/icons/cross.svg' : '/icons/dots.svg'"
        />
      </div>
    </div>

    <!-- Slide-Out -->
    <div
      v-if="currentElement"
      class="smartslider-menu"
      :class="{ '--closed': menu }"
      :style="{
        color: sliderMenuColor,
        backgroundColor: sliderMenuBackgroundColor,
      }">
      <div
        @click.prevent="toggleMenu"
        class="smartslider-control">
        <icon
          size="large"
          :key="menu"
          :src="menu ? '/icons/cross.svg' : '/icons/dots.svg'"
        />
      </div>
      <div class="smartslider-menuContent">
        <smartSlider-locatorMenu
          :category-id="currentElement?.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'category',
    ],
    computed: {
      currentElement() {
        return this.elements.length > this.elementIndex
          ? this.elements[this.elementIndex]
          : null
      },
      hasPreviousElement() {
        return this.elementIndex > 0
      },
      hasNextElement() {
        return this.elementIndex < (this.elements.length - 1)
      },
      previewMedia() {
        if (this.currentElement && this.currentElement.media) {
          return (this.currentElement.media?.links || []).find(link => link.rel === 'preview')?.href
        }
      },
      previewMediaType () {
        return this.currentElement
          && this.currentElement.media
          ? this.currentElement.media.mediatype
          : null
      },
      previewMediaOptions() {
        switch (this.previewMediaType) {
          case 'video':
            return {
              mimeType: `video/${this.currentElement.media.doctype}`
            }
        }
      },
      sliderMenuColor() {
        return this.currentElement?.attributes?.find(attr => attr.name === 'Color_Secondary')?.value
      },
      sliderMenuBackgroundColor() {
        return this.currentElement?.attributes?.find(attr => attr.name === 'Color_Primary')?.value
      }
    },
    data() {
      return {
        elementIndex: 0,
        elements: [],
        menu: true,
      }
    },
    async created() {
      this.elements = await this.$api.findCategories(this.$helper.extractIdFromSlug(this.category.id))
    },
    methods: {
      prevElement() {
        if (this.hasPreviousElement) {
          this.elementIndex--
        }
      },
      nextElement() {
        if (this.hasNextElement) {
          this.elementIndex++
        }
      },
      toggleMenu() {
        this.menu = !this.menu
      }
    },
    inject: [
      '$api',
      '$helper',
    ],
  }
</script>

<style lang="scss" scoped>
  .smartslider-wrapper {
    @apply
      p-4
      top-0
      left-0
      fixed
      w-full
      h-full
      bg-black/[.75];
    z-index: 9999999999;

    .smartslider {
      @apply
        flex
        gap-4
        w-full
        h-full
        flex-col
        rounded-sm;

      .smartslider-content {
        @apply
          min-h-0
          h-full
          w-full
          relative;

        .smartslider-preview {
          @apply
            flex
            w-full
            h-full
            items-center
            justify-center;

          img {
            @apply
              mx-auto
              max-w-full
              max-h-full
              select-none
              pointer-events-none;
          }

          .smartslider-video {
            @apply
              w-full;
          }
        }
      }

      .smartslider-thumbnails {
        @apply
          flex
          gap-2
          w-full
          h-full
          max-h-16
          flex-shrink-0
          items-center
          justify-center;
      }
    }

    .smartslider-arrow {
      @apply
        w-8
        lg:w-20
        flex
        h-full
        absolute
        text-white
        items-center
        cursor-pointer
        justify-center
        hover:bg-black/[.05];

      &.smartslider-arrow--left {
        @apply
          top-0
          left-0;
      }

      &.smartslider-arrow--right {
        @apply
          top-0
          right-0;
      }
    }
  }

  .smartslider-controls {
    @apply
      p-4
      top-4
      right-4
      absolute;

    .smartslider-control {
      @apply
        flex
        p-2
        rounded
        text-white
        items-center
        cursor-pointer
        justify-center
        hover:text-black
        hover:bg-white/[.75];
    }
  }

  .smartslider-menu {
    @apply
      p-4
      w-96
      flex
      flex-col
      gap-4
      top-0
      h-full
      right-0
      absolute
      transition
      ease-in-out
      duration-1000
      translate-x-96
      bg-green-50;

    &.--closed {
      @apply
       translate-x-0
    }

    .smartslider-menuContent {
      @apply
        w-full
        h-full
        overflow-y-auto;
    }
  }
</style>