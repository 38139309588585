<template>
  <div
    @click.prevent="$emit('select')"
    :class="{
      'smart-slider-thumbnail': true,
      '--active': active,
    }">
    <img :src="previewMediaSrc">
  </div>
</template>

<script>
  export default {
    props: [
        'category',
        'active'
    ],
    computed: {
      previewMediaSrc() {
        let mediaSrc = this.category.media?.links?.find(link => link.rel === 'thumbnail')
        if (!mediaSrc) {
          mediaSrc = this.category.media?.links?.find(link => link.rel === 'poster')
        }
        if (!mediaSrc) {
          mediaSrc = this.category.media?.links?.find(link => link.rel === 'preview')
        }
        return mediaSrc?.href
      }
    }
  }
</script>

<style lang="scss" scoped>
  .smart-slider-thumbnail {
    @apply
      w-24
      h-full
      max-w-xs
      cursor-pointer;

    &.--active {
      @apply
        rounded
        border-2
        border-white;
    }

    img {
      @apply
        w-full
        h-full
        rounded
        max-w-full
        max-h-full
        object-cover;
    }
  }
</style>