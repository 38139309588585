import {defineAsyncComponent, markRaw, inject} from 'vue'

export default {
  install(app, options) {
    app.provide('$helper', {
      slugify(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
        // remove accents, swap ñ for n, etc
        let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        let to = "aaaaeeeeiiiioooouuuunc------";
        for (let i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        return str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
      },
      /**
       * Helper to retrieve id from slug
       * @param slug
       * @return {string|null}
       */
      extractIdFromSlug(slug) {
        if (!slug) {
          return undefined
        }
        return slug.substring(slug.lastIndexOf('-') + 1)
      },
      /**
       * Extracts the property value from attribute
       * @param attribute
       * @param property
       * @return {Promise<string|string|*|string>}
       */
      extractPropertyFromAttribute(attribute, property) {
        if (attribute && attribute.startsWith(`${property}:`)) {
          return attribute.substr(attribute.lastIndexOf(`${property}:`) + (property.length + 1))
        }
      },
      /**
       * Retrieves the value of an attribute by given category and name
       * @param category
       * @param attrName
       * @return {*}
       */
      findAttributeValue(category, attrName) {
        return category?.attributes?.find(attr => (attr.name || '').toLowerCase() === attrName.toLowerCase())?.value
      },
      /**
       * @param category
       * @param attrName
       * @param attrValue
       */
      setAttributeValue(category, attrName, attrValue) {
        let attribute = category?.attributes?.find(attr => (attr.name || '').toLowerCase() === attrName.toLowerCase())
        if (attribute) {
          attribute.value = attrValue
        }
      },
      /**
       * @param category
       * @param attrNames
       */
      findAttributeValues(category, attrNames) {
        let retVal = {}
        for (let i = 0; i < attrNames.length; i++) {
          retVal[attrNames[i]] = this.findAttributeValue(category, attrNames[i])
        }
        return retVal
      },
      findAttribute(category, attrName) {
        return category?.attributes?.find(attr => (attr.name || '').toLowerCase() === attrName.toLowerCase())
      },
      /**
       * Resolves the detail component by given category
       * @param category
       * @param templateAttr
       */
      resolveComponentTypeByCategory(category, templateAttr) {
        let component = 'page'
        switch (category.categorytype) {
          case 'media':
            switch (category?.media?.mediatype) {
              case 'document':
                if (category?.media?.doctype === 'pdf') {
                  component = 'pdf'
                }
                break
              case 'video':
                component = 'video'
                if (templateAttr?.value === 'WSM') {
                  component = 'lightgallery'
                }
                break
              case 'audio':
                component = 'audio'
                break
              case 'image':
                if (templateAttr?.value === 'WSM') {
                  component = 'lightgallery'
                }
                break;
              default:
                component = 'page'
                break
            }
            break
          case 'meeting':
            component = 'meeting'
            break
          case 'stream':
          case 'tvstream':
            component = 'stream'
            break
          case 'frame':
            if (category.frameopeninnewtab) {
              component = 'newframe'
            } else
              component = 'iframe'
            break
          case 'folder':
            component = 'folder'
            if (templateAttr?.value === 'Page') {
              component = 'page'
            }
            if (templateAttr?.value === 'WSM') {
              component = 'folderflickr'
            }
            if (templateAttr?.value === 'WSMBasket') {
              component = 'folderlist'
            }
            if (templateAttr?.value === 'Slider') {
              component = 'smartslider'
            }
            break
          case 'quicklink':
            component = 'quicklink'
            if (templateAttr?.value === 'WSM') {
              component = 'quicklinkflickr'
            }
            break
        }
        return component
      },
      resolveVueComponent($componentType) {
        switch ($componentType) {
          case 'page':
            return markRaw(defineAsyncComponent(() => import('../components/views/Page')))
          case 'folder':
            return markRaw(defineAsyncComponent(() => import('../components/views/Folders')))
          case 'folderflickr':
            return markRaw(defineAsyncComponent(() => import('../components/views/FoldersFlickr')))
          case 'folderlist':
            return markRaw(defineAsyncComponent(() => import('../components/views/FoldersList')))
          case 'lightgallery':
            return markRaw(defineAsyncComponent(() => import('../components/views/LightGallery')))
          case 'audio':
            return markRaw(defineAsyncComponent(() => import('../components/views/Audio')))
          case 'video':
            return markRaw(defineAsyncComponent(() => import('../components/views/Video')))
          case 'iframe':
            return markRaw(defineAsyncComponent(() => import('../components/views/Frame')))
          case 'newframe':
            return markRaw(defineAsyncComponent(() => import('../components/views/NewFrame')))
          case 'pdf':
            return markRaw(defineAsyncComponent(() => import('../components/views/PDF')))
          case 'stream':
            return markRaw(defineAsyncComponent(() => import('../components/views/Stream')))
          case 'meeting':
            return markRaw(defineAsyncComponent(() => import('../components/views/Meeting')))
          case 'quicklink':
            return markRaw(defineAsyncComponent(() => import('../components/views/Smartfolders')))
          case 'quicklinkflickr':
            return markRaw(defineAsyncComponent(() => import('../components/views/SmartfoldersFlickr')))
          case 'smartslider':
            return markRaw(defineAsyncComponent(() => import('../components/views/SmartSlider')))
          default:
            return markRaw(defineAsyncComponent(() => import('../components/views/404')))
        }
      },
      /**
       * Resolve properties for custom vue component
       * @param dynamicComponents
       * @param layout
       */
      resolveComponents(dynamicComponents, layout) {
        let components = []
        for (let i = 0; i < dynamicComponents.length; i++) {
          let dynamicComponent = dynamicComponents[i]
          if (dynamicComponent) {
            const component = {
              layout,
              showInFullscreen: false,
              category: dynamicComponent.category,
              componentType: dynamicComponent.component,
            }

            switch (dynamicComponent.component) {
              case 'page':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Page')))
                break
              case 'folder':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Folders')))
                break
              case 'folderflickr':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/FoldersFlickr')))
                break
              case 'folderlist':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/FoldersList')))
                break
              case 'lightgallery':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/LightGallery')))
                break
              case 'video':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Video')))
                break
              case 'iframe':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Frame')))
                component.showInFullscreen = true
                break
              case 'newframe':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/NewFrame')))
                component.showInFullscreen = true
                break
              case 'pdf':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/PDF')))
                component.showInFullscreen = true
                break
              case 'stream':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Stream')))
                break
              case 'meeting':
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/Meeting')))
                component.showInFullscreen = true
                break
              default:
                component.vueComponent = markRaw(defineAsyncComponent(() => import('../components/views/404')))
                break
            }
            components.push(component)
          }
        }
        return components
      },
      /**
       * @param $api
       * @param category
       * @return {Promise<*[]>}
       */
      async buildFooter($api, category) {
        const footerAttr = this.findAttributeValue(category, 'CMS-Footer')
        let navigationFooter = []
        if (footerAttr) {
          const categoryIds = footerAttr.split(',')
          for (let i = 0; i < categoryIds.length; i++) {
            let categoryId = categoryIds[i]
            if (categoryId && categoryId.includes(':')) {
              categoryId = categoryId.split(':')[1]
              let category = await $api.findCategory(categoryId)
              if (category) {
                navigationFooter.push(category)
              }
            }
          }
        }
        return navigationFooter
      },
    })
  }
}