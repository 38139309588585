<template>
  <div class="smart-slider-locator-menu">
    <div class="header">
      <div class="title">Locators</div>
      <div class="controls">
        <c-dropdown
          class="cursor-pointer"
          :entries="locatorTypeEntries">
          <icon
            size="large"
            src="/icons/dots.svg">
          </icon>
        </c-dropdown>
      </div>
    </div>
    <template v-if="category">
      <smartSlider-locator
        :key="idx"
        class="mb-4"
        :in-preview="locator.thumbin"
        :out-preview="locator.thumbout"
        v-for="(locator, idx) in category?.locators?.locators">
        {{ locator?.note }}
      </smartSlider-locator>
    </template>
  </div>
</template>

<script>
  export default {
    props: [
      'categoryId',
    ],
    data() {
      return {
        category: null,
        locatorTypeEntries: []
      }
    },
    watch: {
      categoryId: {
        deep: true,
        immediate: true,
        async handler() {
          this.category = await this.$api.findCategory(this.categoryId)
          this.locatorTypeEntries = (this.category?.locators?.locatorTypes || []).map(locator => {
            return {
              id: locator.id,
              label: locator.name
            }
          })
        }
      }
    },
    inject: [
      '$api',
    ]
  }
</script>

<style lang="scss" scoped>
  .smart-slider-locator-menu {
    .header {
      @apply
        mb-4
        flex
        w-full
        text-xl
        font-medium
        items-center
        justify-between;
    }
  }
</style>