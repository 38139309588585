<template>
  <div class="locator-container">
    <div class="locator">
      <div class="locator-in">
        <img :src="inPreview" />
      </div>
      <div class="locator-out">
        <img :src="outPreview" />
      </div>
    </div>
    <div class="locator-note">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'inPreview',
      'outPreview',
    ]
  }
</script>

<style lang="scss">
  .locator-container {
    @apply
    flex
    gap-0.5
    flex-col
    font-normal;

    .locator {
      @apply
      gap-2
      flex;
    }

    .locator-in,
    .locator-out {
      @apply
      w-48
      h-20
      bg-green-500;
    }
  }
</style>