export default {
  auth: {
    login: 'Log in',
    register: 'Register',
    register_success: 'Successful registered!',
    register_success_message: 'We have sent you an email. Enter a password and log in.',
    forgot: 'Forgot Password?',
    login_as_guest: 'back',
    reset_password: 'Reset password',
    authenticate_error_message: 'There is an error. Check the input fields.',
    reset_password_message: 'Your password has been successfully reset. We have sent you an email. Click on the link in the email and set a new password.',
    forgot_subject: 'Set your new password',
    set_password: 'Set password',
    set_password_confirm_failed: 'The two passwords are not identical.',
    set_password_success_message: 'The password was changed sucessfully.',
    invalid_request: 'The password cannot be reset, please request a new password.',
    logout: 'Log out',
  },
  passwordemail: {
    subject: 'Reset password',
    title: 'New Passwort',
    subtitle: 'Set your new password with a click on the button',
    buttontitle: 'Set password now'
  },
  generic: {
    from: 'from',
    until: 'to',
    role: 'role',
    title: 'title',
    email: 'email',
    search: 'search',
    firstname: 'first name',
    lastname: 'last name',
    company: 'company',
    username: 'user name',
    password: 'password',
    cancel: 'cancel',
    password_confirm: 'confirm password',
    message: 'message',
    upload: 'upload',
    download: 'download',
    new_folder: 'new folder',
    edit_folder: 'edit category',
    download_original: 'download original',
    download_preview: 'download preview',
    create: 'create',
    sort: 'sort',
    sort_at: 'sort to',
    apply: 'apply',
    delete: 'delete',
    edit: 'edit',
    copy: 'copy',
    move: 'move',
    paste: 'paste',
    select_all: 'select all',
    deselect_all: 'deselect all',
    save: 'save',
    select: 'select',
    menu: 'menu',
  },
  category: {
    current_users: 'current users'
  },
  meeting: {
    invite: 'invite',
    end_meeting: 'end meeting',
    invite_users: 'invite users',
    invite_guests: 'invite guests (email)',
    invite_successfull: 'the invitation was sent successfully.',
    end_meeting_message: 'You end the meeting. Note that all participants also leave the meeting with the confirmation. Do you want to end the meeting for all participants?',
  },
  meetingemail: {
    title: 'Invitation for a meeting',
    subtitle: 'For a meting as agreed',
    subtitlewithdate: 'The meeting starts at xxxfromxxx and ends at xxxuntilxxx',
    buttontitle: 'Join meeting now'
  },
  clientSelector: {
    no_projects_found: 'no projects were found',
    projects: 'projects'
  },
  shared: {
    share: 'share',
    shareurl: 'share address',
    copyclipboard: 'copy to clipboard',
    copied: 'copied successfully to clipboard',
    errorcopy: 'error writing clipboard',
    create: 'create new share',
    created: 'new share was created',
    delete: 'delete share',
    deleted: 'share was deleted',
    delete_text: 'Do you really want to delete the share?'
  },
  folders: {
    sortDirection: 'sort order',
    sortDirection_asc: 'ascending',
    sortDirection_desc: 'descending',
    uploading: 'Just a moment until all files are uploaded...',
    deleting: 'Just a moment until all files are deleted...',
    delete_category_text: 'Do you really want to delete the category?',
    sortSaved: 'new sort order saved',
    sortTypes: {
      default: 'default sort',
      date: 'date',
      name: 'name',
      size: 'size',
      attribute: 'attribute',
    }
  },
  frames: {
    title: 'Open New Page',
    open: 'Open',
    message: 'A new Web-Page is to be opened. Do you want to open the Web-Page?'
  }
}